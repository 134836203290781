import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useEnum } from '../../hooks/useEnum';
import type { ReduxState } from '../../store';
import MetaTags from '../MetaTags';
import { useTranslation } from '../Translate';

export default function SearchMetaTags() {
  const { formValues, data, region } = useSelector((state: ReduxState) => ({
    formValues: state.rentCar.filters,
    data: state.rentCar.data,
    region: state.app.region,
  }));
  const [manufacturers] = useEnum('manufacturers');
  const [bodyTypes] = useEnum('type');
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (!formValues || !data || !data.items.length) return '';

    let title = '';
    // Značky
    if (formValues.manufacturer.length) {
      title += formValues.manufacturer.map(id => manufacturers.filter(item => id === item.id)[0].name).join(', ');
      title += ' ';
    }
    // Karoserie
    if (formValues.type.length) {
      title += formValues.type
        .map(id => {
          const name = bodyTypes.filter(item => id === item.value)[0].label;
          if (name === name.toUpperCase()) return name;
          return name.slice(0, 1).toLowerCase() + name.slice(1);
        })
        .join(', ');
      title += ' ';
    }
    // Město
    if (formValues.location.name !== '') {
      title += `v ${formValues.location.name} `;
    }

    // Cena
    title += `${t('rentCar.meta.title.from', { _context: region.toLowerCase() })} `;
    return title;
  }, [formValues, data, t, bodyTypes, manufacturers]);

  return <MetaTags title={`${t('rentCar.meta.title.cars')} ${title}| HoppyGo.com`} />;
}
